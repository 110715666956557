@tailwind base;
@tailwind components;
@tailwind utilities;
 

@layer base {
  :root {
    --background: 0 0% 100%;
    --page-background: 0 5% 88%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    /* --primary: 0 72.2% 50.6%; */
    /* --primary: 4 77% 48%; */
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --radius: 0.5rem;
  }

  .one800 {
    --primary: 4 77% 48%;
    --ring: 0 72.2% 50.6%;
  }

  .wholesale {
    --primary: 217.55 77.65% 35.1%;
    --ring: 217.55 77.65% 35.1%;
  }

  .metal {
    --primary: 217.55 77.65% 35.1%;
    --ring: 217.55 77.65% 35.1%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    /* --primary: 0 72.2% 50.6%; */
    --primary: 4 77% 48%;
    --primary-foreground: 0 85.7% 97.3%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 72.2% 50.6%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-page-background text-foreground;
  }
}